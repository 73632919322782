import React from "react";
import { Route, Redirect } from "react-router-dom";
import PrivateRoute from "../../privateRoutes";
import AppLayout from "../appLayout";
import LoanHome from "../../components/personalLoan/home/loanHome";
import LoanApplicationForm from "../../components/personalLoan/loanApplicationForm/loanApplicationForm";
import CustomerBankDetails from "../../components/personalLoan/customerBankDetails/customerBankDetails";
import LoanDetails from "../../components/personalLoan/loanDetails/loanDetails";
import LoanEligibilityDetails from "../../components/personalLoan/loanEligibility/loanEligibilityDetails";
import LoanConfirmationDetails from "../../components/personalLoan/loanConfirmation/loanConfirmation";
import LoanSanctionDetails from "../../components/personalLoan//loanSanctionDetails/loanSanctionDetails";
import KycForm from "../../components/personalLoan/kycForm/kycForm";
import LoanStatus from "../../components/personalLoan/loanStatus/loanStatus";
import LoanPrepayment from "../../components/personalLoan/loanPrepayment/loanPrepayment";
import LoanForeClosure from "../../components/personalLoan/loanForeClosure/loanForeClosure";
import LoanEMIMissedDetails from "../../components/personalLoan/loanEMIMissedDetails/loanEMIMissedDetails";
import LoanIssueGovernance from "../../components/personalLoan/LoanIssueGovernance/loanIssueGovernance";

export default function Application() {
  return (
    <React.Fragment>
      <Route
        path={"/application"}
        exact
        component={() => <Redirect to={"/application/home"} />}
      />
      <PrivateRoute exact path={"/application/home"}>
        <AppLayout>
          <LoanHome />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute exact path={"/application/customerDetails"}>
        <AppLayout>
          <LoanApplicationForm />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute exact path={"/application/customerBankDetails"}>
        <AppLayout>
          <CustomerBankDetails />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute exact path={"/application/loanDetails"}>
        <AppLayout>
          <LoanDetails />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute exact path={"/application/loanEligibilityDetails"}>
        <AppLayout>
          <LoanEligibilityDetails />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute exact path={"/application/loanSanctionDetails"}>
        <AppLayout>
          <LoanSanctionDetails />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute exact path={"/application/kycForm"}>
        <AppLayout>
          <KycForm />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute exact path={"/application/loanConfirmationDetails"}>
        <AppLayout>
          <LoanConfirmationDetails />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute exact path={"/application/loanStatus"}>
        <AppLayout>
          <LoanStatus />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute exact path={"/application/loanPrePayment"}>
        <AppLayout>
          <LoanPrepayment />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute exact path={"/application/loanForeClosure"}>
        <AppLayout>
          <LoanForeClosure />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute exact path={"/application/loanEMIMissingDetails"}>
        <AppLayout>
          <LoanEMIMissedDetails />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute exact path={"/application/IssueGovernance"}>
        <AppLayout>
          <LoanIssueGovernance />
        </AppLayout>
      </PrivateRoute>
    </React.Fragment>
  );
}
