import React, { Fragment, useState } from "react";
import styles from "../../../styles/auth/auth.module.scss";
import { buttonTypes } from "../../shared/button/utils";
import Button from "../../shared/button/button";
import { Link, useHistory } from "react-router-dom";
import Input from "../../shared/input/input";
import ErrorMessage from "../../shared/error-message/errorMessage";
import formStyles from "./../../shared/input/input.module.scss";
import { MenuItem, FormControl, Select, Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import useStyles from "../style";
import LoanAgreement from "../../../assets/images/LoanAgreement.jpg";
import bankFormalities from "../../../assets/images/bankFormalities.jpg";

import {
  formSubmitAPICall,
  bankDetailsInitAPICall,
  bankAdditionalDetailsInitAPICall,
  statusAPICall,
  loanStatusAPICall,
  loanAgreementDetailsConfirmAPICall,
  loanUpdateAPICall,
} from "../../../api/personal.loan.api";

export default function CustomerBankDetails() {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [accountName, setAccountName] = useState();
  const [accountType, setAccountType] = useState("saving");
  const [accountNumber, setAccountNumber] = useState();
  const [ifscCode, setIfscCode] = useState();

  const [showAdditionalBankDetailsBtn, setShowAdditionalBankDetailsBtn] =
    useState(false);

  const [showLoanAgreementBtn, setShowLoanAgreementBtn] = useState(false);

  const [showBankDetailsForm, setShowBankDetailsForm] = useState(true);
  const [
    showAdditionalBankDetailsContent,
    setShowAdditionalBankDetailsContent,
  ] = useState(false);

  const [showLoanAgreementDetails, setShowLoanAgreementDetails] =
    useState(false);

  const [bankRedirectUrl, setBankRedirectUrl] = useState();

  const [loanAgreementUrl, setLoanAgreementUrl] = useState();

  const [inlineError, setInlineError] = useState({
    accountName_error: "",
    accountType_error: "",
    accountNumber_error: "",
    ifscCode_error: "",
  });

  function checkAccountName() {
    if (!accountName) {
      setInlineError((inlineError) => ({
        ...inlineError,
        accountName_error: "Account Name cannot be empty",
      }));
      return false;
    }
    return true;
  }

  function checkAccountType() {
    if (!accountType) {
      setInlineError((inlineError) => ({
        ...inlineError,
        accountType_error: "Please select Account Type",
      }));
      return false;
    }
    return true;
  }

  function checkAccountNumber() {
    if (!accountNumber) {
      setInlineError((inlineError) => ({
        ...inlineError,
        accountNumber_error: "Account number cannot be empty",
      }));
      return false;
    }
    return true;
  }

  function checkIFSCCode() {
    if (!ifscCode) {
      setInlineError((inlineError) => ({
        ...inlineError,
        ifscCode_error: "IFSC code cannot be empty",
      }));
      return false;
    }
    return true;
  }

  const makeLoanUpdateAPICall = async (loanConfirmData) => {
    try {
      const data = await loanUpdateAPICall(loanConfirmData);
    } catch (err) {
      console.log("error in makeLoanUpdateAPICall API", err);
    } finally {
    }
  };

  const makeLoanAgreementDetailsConfirmAPICall = async (loanAggmtData) => {
    try {
      const data = await loanAgreementDetailsConfirmAPICall(loanAggmtData);
      sessionStorage.setItem(
        "loanConfirmationDetails",
        JSON.stringify(data[0])
      );
      sessionStorage.setItem("orderId", data[0].message.order.id);
      setIsLoading(false);
      history.push("/application/loanConfirmationDetails");
      //makeLoanUpdateAPICall(data[0]);
    } catch (err) {
      console.log("error in makeLoanAgreementDetailsConfirmAPICall API", err);
    } finally {
    }
  };

  const makeLoanAgreementStatusAPICall = async () => {
    setIsLoading(true);
    try {
      const data = await loanStatusAPICall();
      makeLoanAgreementDetailsConfirmAPICall(data[0]);
    } catch (err) {
      console.log("error in makeLoanAgreementStatusAPICall API", err);
    } finally {
    }
  };

  const makeAdditionalBankDetailsInitAPICall = async (addBankData) => {
    setIsLoading(true);
    try {
      const data = await bankAdditionalDetailsInitAPICall(addBankData);
      console.log(
        "setLoanAgreementUrl==",
        data[0].message.order.items[0].xinput.form.url
      );

      console.log("paymentID==", data[0].message.order.payments[0].id);
      setLoanAgreementUrl(data[0].message.order.items[0].xinput.form.url);
      setShowAdditionalBankDetailsContent(false);
      setShowLoanAgreementDetails(true);
      setIsLoading(false);
    } catch (err) {
      console.log("error in makeAdditionalBankDetailsInitAPICall API", err);
    } finally {
    }
  };

  const makeBankDetailsStatusAPICall = async () => {
    console.log("inside makeBankDetailsStatusAPICall===");
    try {
      const data = await loanStatusAPICall();
      setShowBankDetailsForm(false);
      setShowAdditionalBankDetailsContent(true);
      setBankRedirectUrl(data[0].message.order.items[0].xinput.form.url);
      setIsLoading(false);
      makeAdditionalBankDetailsInitAPICall(data[0]);
    } catch (err) {
      console.log("error in makeBankDetailsStatusAPICall API", err);
    } finally {
    }
  };

  const makeBankDetailsInitAPICall = async (kycInitSubmitData) => {
    try {
      const data = await bankDetailsInitAPICall(kycInitSubmitData);
      setShowBankDetailsForm(false);
      setShowAdditionalBankDetailsContent(true);
      setBankRedirectUrl(data[0].message.order.items[0].xinput.form.url);
      setIsLoading(false);
    } catch (err) {
      console.log("error in makeBankDetailsInitAPICall API", err);
    } finally {
    }
  };

  const makeBankDetailsAPICall = async (formData) => {
    try {
      const kycInitSubmitData = JSON.parse(
        sessionStorage.getItem("kycSubmitDetails")
      );
      formData.append(
        "formId",
        kycInitSubmitData?.message.order.items[0].xinput.form.id
      );
      const data = await formSubmitAPICall(
        formData,
        kycInitSubmitData?.message.order.items[0].xinput.form.url
      );
      sessionStorage.setItem("bankDetailsSubmitID", data?.submission_id);
      makeBankDetailsInitAPICall(kycInitSubmitData);
    } catch (err) {
      console.log("error in makeBankDetailsAPICall API", err);
    } finally {
    }
  };

  function submitBankAccountDetails(e) {
    e.preventDefault();
    setIsLoading(true);
    let bankDetailsFormData = new FormData();
    bankDetailsFormData.append("accNo", accountNumber);
    bankDetailsFormData.append("ifscCode", ifscCode);
    bankDetailsFormData.append("accHolderName", accountName);
    bankDetailsFormData.append("acctype", accountType);
    makeBankDetailsAPICall(bankDetailsFormData);
  }

  const navigateToBankDetails = async () => {
    setShowAdditionalBankDetailsBtn(true);
    window.open(bankRedirectUrl);
  };

  const navigateToLoanAgreement = async () => {
    setShowLoanAgreementBtn(true);
    window.open(loanAgreementUrl);
  };

  return (
    <div className={styles.auth_form}>
      <Typography component="div" my={3} ml={3}>
        <Link to="/application/home" className={classes.backToHome}>
          &#60; Back to Home
        </Link>
      </Typography>

      {showLoanAgreementDetails && (
        <Grid
          container
          spacing={0}
          sx={{ width: "auto", height: "auto" }}
          mx={3}
          mb={5}
        >
          <Grid
            item
            xs={4}
            className={classes.bankInformationList}
            sx={{
              borderTopLeftRadius: "12px",
              borderBottomLeftRadius: "12px",
            }}
          >
            <Typography component="div" my={3} ml={3}>
              <img src={LoanAgreement} height="200px" />
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            pl={3}
            className={classes.bankInformationList}
            sx={{
              borderTopRightRadius: "12px",
              borderBottomRightRadius: "12px",
            }}
          >
            <Typography component="div" variant="h3" my={2} color="#196aab">
              Loan Agreement
            </Typography>
            {showLoanAgreementBtn ? (
              <div className="pb-4">
                <Typography component="div" my={2} color="#196aab">
                  Thank you for completing Loan agreement formalities and please
                  click on
                  <span style={{ fontWeight: "bold" }}> Confirm </span>
                  for loan disbursement.
                </Typography>
                <Typography component="div" my={2} color="#196aab">
                  <Button
                    isloading={isLoading ? 1 : 0}
                    disabled={isLoading}
                    button_type={buttonTypes.primary}
                    button_hover_type={buttonTypes.primary_hover}
                    button_text="Confirm"
                    type="button"
                    onClick={makeLoanAgreementStatusAPICall}
                  />
                </Typography>
              </div>
            ) : (
              <Fragment>
                <Typography component="div" my={2} color="#196aab">
                  <span style={{ fontWeight: "bold" }}>Note :</span> Please
                  complete loan agreement formalities by clicking below link.
                </Typography>
                <Typography component="div" my={2}>
                  <a
                    href="#"
                    rel="noreferrer"
                    target="_blank"
                    style={{ fontWeight: "bold" }}
                    onClick={navigateToLoanAgreement}
                  >
                    Click to Complete Loan Agreement
                  </a>
                </Typography>
              </Fragment>
            )}
          </Grid>
        </Grid>
      )}

      {showAdditionalBankDetailsContent && (
        <Grid
          container
          spacing={0}
          className={classes.productItemContainerList}
          sx={{ width: "auto", height: "auto" }}
          mx={3}
          mb={5}
        >
          <Grid
            item
            xs={4}
            className={classes.bankInformationList}
            sx={{
              borderTopLeftRadius: "12px",
              borderBottomLeftRadius: "12px",
            }}
          >
            <Typography component="div" my={3} ml={3}>
              <img src={bankFormalities} height="200px" />
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            pl={3}
            className={classes.bankInformationList}
            sx={{
              borderTopRightRadius: "12px",
              borderBottomRightRadius: "12px",
            }}
          >
            <Typography component="div" variant="h3" my={2} color="#196aab">
              Additional Bank Information
            </Typography>
            {showAdditionalBankDetailsBtn ? (
              <div className="pb-4">
                <Typography component="div" my={2} color="#196aab">
                  <span>
                    Thank you for submitting additional bank information and
                    please click
                    <span style={{ fontWeight: "bold" }}> Continue </span> to
                    proceed further.
                  </span>
                </Typography>
                <Button
                  isloading={isLoading ? 1 : 0}
                  disabled={isLoading}
                  button_type={buttonTypes.primary}
                  button_hover_type={buttonTypes.primary_hover}
                  button_text="Continue"
                  type="button"
                  onClick={() => {
                    setIsLoading(true);
                    makeBankDetailsStatusAPICall();
                  }}
                />
              </div>
            ) : (
              <Fragment>
                <Typography component="div" my={2} color="#196aab">
                  <span style={{ fontWeight: "bold" }}>Note :</span> Please
                  complete Additional Bank formalities by clicking below link.
                </Typography>
                <Typography component="div" my={2}>
                  <a
                    href={bankRedirectUrl}
                    rel="noreferrer"
                    target="_blank"
                    style={{ fontWeight: "bold" }}
                    onClick={navigateToBankDetails}
                  >
                    Click to Complete Bank details
                  </a>
                </Typography>
              </Fragment>
            )}
          </Grid>
        </Grid>
      )}

      {showBankDetailsForm && (
        <div className={styles.auth_form}>
          <Typography
            component="div"
            variant="h3"
            my={2}
            align="center"
            color="#196aab"
          >
            Customer Bank Details
          </Typography>
          <form onSubmit={submitBankAccountDetails}>
            <Grid container columnSpacing={10} mt={2}>
              <Grid xs={12} md={6}>
                <Input
                  id="accountName"
                  name="accountName"
                  type="text"
                  placeholder="Account Holder Name"
                  label_name="Account Holder Name"
                  autoComplete="off"
                  has_error={inlineError.accountName_error}
                  onChange={(event) => {
                    setAccountName(event.target.value);
                    setInlineError((inlineError) => ({
                      ...inlineError,
                      accountName_error: "",
                    }));
                  }}
                  onBlur={checkAccountName}
                  required
                />
                {inlineError.accountName_error && (
                  <ErrorMessage>{inlineError.accountName_error}</ErrorMessage>
                )}
              </Grid>
              <Grid xs={12} md={6}>
                <div className="py-2">
                  <FormControl style={{ width: "100%" }}>
                    <label
                      id="accountType"
                      className={`${formStyles.form_label} ${formStyles.required}`}
                    >
                      Account Type
                    </label>
                    <Select
                      style={{ height: "45px" }}
                      required
                      size="small"
                      labelId="accountType"
                      id="account-select"
                      value={accountType}
                      label="Select account type"
                      onChange={(e) => {
                        setInlineError((error) => ({
                          ...inlineError,
                          accountType_error: "",
                        }));
                        setAccountType(e.target.value);
                      }}
                      onBlur={checkAccountType}
                    >
                      <MenuItem value="current">Current</MenuItem>
                      <MenuItem value="saving">Saving</MenuItem>
                    </Select>
                    {inlineError.accountType_error && (
                      <ErrorMessage>
                        {inlineError.accountType_error}
                      </ErrorMessage>
                    )}
                  </FormControl>
                </div>
              </Grid>
              <Grid xs={12} md={6}>
                <Input
                  id="accountNumber"
                  name="accountNumber"
                  type="number"
                  placeholder="Bank Account Number"
                  label_name="Bank Account Number"
                  autoComplete="off"
                  has_error={inlineError.accountNumber_error}
                  onChange={(event) => {
                    setAccountNumber(event.target.value);
                    setInlineError((inlineError) => ({
                      ...inlineError,
                      accountNumber_error: "",
                    }));
                  }}
                  onBlur={checkAccountNumber}
                  required
                />
                {inlineError.accountNumber_error && (
                  <ErrorMessage>{inlineError.accountNumber_error}</ErrorMessage>
                )}
              </Grid>
              <Grid xs={12} md={6}>
                <Input
                  id="ifscCode"
                  name="ifscCode"
                  type="ifscCode"
                  placeholder="Bank IFSC Code"
                  label_name="Bank IFSC Code"
                  autoComplete="off"
                  has_error={inlineError.ifscCode_error}
                  onChange={(event) => {
                    setIfscCode(event.target.value);
                    setInlineError((inlineError) => ({
                      ...inlineError,
                      ifscCode_error: "",
                    }));
                  }}
                  onBlur={checkIFSCCode}
                  required
                />
                {inlineError.ifscCode_error && (
                  <ErrorMessage>{inlineError.ifscCode_error}</ErrorMessage>
                )}
              </Grid>
              <Grid xs={12}>
                <div className="py-4 text-center">
                  <Button
                    isloading={isLoading ? 1 : 0}
                    disabled={isLoading}
                    button_type={buttonTypes.primary}
                    button_hover_type={buttonTypes.primary_hover}
                    button_text="Submit"
                    type="submit"
                  />
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </div>
  );
}
