import React, { Fragment, useState, useEffect } from "react";
import styles from "../../../styles/auth/auth.module.scss";
import { buttonTypes } from "../../shared/button/utils";
import Button from "../../shared/button/button";
import { Link, useHistory } from "react-router-dom";
import formstyles from "../../../styles/auth/auth.module.scss";
import Box from "@mui/material/Box";
import useStyles from "../style";
import Input from "../../shared/input/input";
import Slider from "@mui/material/Slider";
import ErrorMessage from "../../shared/error-message/errorMessage";
import { Typography } from "@mui/material";
import kyc from "../../../assets/images/kyc.png";
import loanSanction from "../../../assets/images/loan_Sanction.jpg";

import Grid from "@mui/material/Grid";
import {
  formSubmitAPICall,
  loanAmountSelectAPICall,
} from "../../../api/personal.loan.api";

function LoanDetails() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();
  const [loanAmount, setLoanAmount] = useState();
  const [loanTerm, setLoanTerm] = useState();
  const [loanDetails, setLoanDetails] = useState();
  const [loanDisbursementDetails, setLoanDisbursementDetails] = useState();
  const [bankName, setBankName] = useState();
  const [bankLogoUrl, setBankLogoUrl] = useState();
  const [showLoanSanctionDetails, setShowLoanSanctionDetails] = useState(false);

  const loanMinValue = 200000;
  const [loanMaxValue, setLoanMaxValue] = useState();
  const [selectedAmount, setSelectedAmount] = useState();
  const [priceRangeValue, setPriceRangeValue] = useState([]);

  const loanMinTermValue = 10;
  const [loanMaxTermValue, setLoanMaxTermValue] = useState();
  const [selectedLoanTerm, setSelectedLoanTerm] = useState();
  const [termRangeValue, setTermRangeValue] = useState([]);

  const [inlineError, setInlineError] = useState({
    loanAmount_error: "",
    loanTerm_error: "",
  });

  function checkLoanAmount() {
    if (!loanAmount) {
      setInlineError((inlineError) => ({
        ...inlineError,
        accountName_error: "Loan Amount cannot be empty",
      }));
      return false;
    }
    return true;
  }

  function checkLoanTerm() {
    if (!loanTerm) {
      setInlineError((inlineError) => ({
        ...inlineError,
        accountType_error: "Loan term cannot be empty",
      }));
      return false;
    }
    return true;
  }

  const makeloanAmountSelectAPICall = async () => {
    try {
      const data = await loanAmountSelectAPICall();
      sessionStorage.setItem("loanAmountDetails", JSON.stringify(data));
      setBankName(data[0].message.order.provider.descriptor.short_desc);
      setBankLogoUrl(data[0].message.order.provider.descriptor.images[0].url);
      setLoanDetails(data[0].message.order.items[0]);
      setLoanDisbursementDetails(data[0].message.order.quote);
      setShowLoanSanctionDetails(true);
    } catch (err) {
      console.log("error in select API");
    } finally {
    }
  };

  const makeLoanRequestAPICall = async (formData) => {
    const data = JSON.parse(sessionStorage.getItem("loanEligibilityDetails"));
    const formUrl = data[0]?.message.order.items[0].xinput.form.url;
    setIsLoading(true);
    try {
      const data = await formSubmitAPICall(formData, formUrl);
      console.log("submit data==", data);
      sessionStorage.setItem("loanAmountFormSubmitID", data?.submission_id);
      makeloanAmountSelectAPICall();
    } catch (err) {
      console.log("error in search API");
    } finally {
    }
  };

  function submitLoanDetails(e) {
    e.preventDefault();
    let loanFormData = new FormData();
    loanFormData.append("requestAmount", loanAmount);
    loanFormData.append("requestTerm", loanTerm);
    makeLoanRequestAPICall(loanFormData);
  }

  const makeLowecase = (text) => {
    return (text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()).replace(
      /_/g,
      " "
    );
  };

  const handlePriceRangeChange = (event, newValue) => {
    setSelectedAmount(newValue[1]);
    setPriceRangeValue(newValue);
    setLoanAmount(newValue[1]);
    console.log("slide onchange loan amount", newValue[1]);
  };

  const handleLoanTermRangeChange = (event, newValue) => {
    setSelectedLoanTerm(newValue[1]);
    setTermRangeValue(newValue);
    setLoanTerm(newValue[1]);
    console.log("slide onchange Term", newValue[1]);
  };

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("loanEligibilityDetails"));
    const maxLoanAmount = data[0].message.order.quote.breakup[0].price.value;
    setLoanMaxValue(maxLoanAmount);
    setSelectedAmount(maxLoanAmount);
    setPriceRangeValue([200000, maxLoanAmount]);
    setLoanMaxTermValue(30);
    setSelectedLoanTerm(30);
    setTermRangeValue([10, 30]);
  }, []);

  return (
    <div>
      <Typography component="div" mt={2} ml={6}>
        <Link to="/application/home" className={classes.backToHome}>
          &#60; Back to Home
        </Link>
      </Typography>
      {showLoanSanctionDetails ? (
        <Fragment>
          <div className={formstyles.auth_form}>
            <Grid
              container
              spacing={0}
              sx={{ width: "auto", height: "auto" }}
              mx={3}
              mb={5}
            >
              <Grid item xs={5}>
                <Typography component="div" my={3} ml={3}>
                  <img src={loanSanction} height="200px" />
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography component="div" align="center" mt={2}>
                  <img
                    src={bankLogoUrl}
                    className={classes.brandImage}
                    alt="brand-image"
                  />
                </Typography>
                <Typography
                  component="div"
                  variant="h3"
                  mt={2}
                  color="#196aab"
                  align="center"
                >
                  {bankName}
                </Typography>
                <Typography
                  component="div"
                  variant="h6"
                  mt={2}
                  color="#196aab"
                  align="center"
                >
                  Your Loan Sanction Details
                </Typography>
                <div className="pb-4">
                  <Typography component="div" align="center" mt={3}>
                    <Button
                      button_type={buttonTypes.primary}
                      button_hover_type={buttonTypes.primary_hover}
                      button_text={
                        <>
                          <img
                            src={kyc}
                            alt="kyc"
                            style={{ height: "20px", marginRight: "10px" }}
                          />
                          Click to Complete KYC
                        </>
                      }
                      type="submit"
                      onClick={(e) => {
                        history.push(`/application/kycForm`);
                      }}
                    >
                      Click to Apply
                    </Button>
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>

          <Grid
            container
            spacing={0}
            className={classes.productItemContainerList}
            sx={{ width: "auto", height: "auto" }}
            mx={3}
            mb={5}
          >
            <Grid
              item
              xs={12}
              className={classes.bankInformationList}
              sx={{
                borderRadius: "12px",
              }}
            >
              <Typography
                component="div"
                variant="h5"
                className={classes.headerTitle}
              >
                Sanctioned Loan Amount
              </Typography>

              {loanDisbursementDetails.breakup.map((item, ind) => {
                return (
                  <Box style={{ display: "inline-block" }} key={ind}>
                    <Typography
                      variant="body"
                      style={{ display: "block", marginRight: "30px" }}
                      className={classes.descriptionItemList}
                    >
                      {makeLowecase(item.title)}
                    </Typography>
                    <Typography
                      variant="body"
                      style={{
                        display: "block",
                        fontWeight: "bold",
                      }}
                      className={classes.descriptionItemList}
                    >
                      {item.price.value}
                    </Typography>
                  </Box>
                );
              })}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            className={classes.productItemContainerList}
            sx={{ width: "auto", height: "auto" }}
            mx={3}
            mb={10}
          >
            <Grid
              item
              xs={12}
              sx={{
                borderRadius: "12px",
              }}
              className={classes.productDetailsTypo}
            >
              <Typography
                component="div"
                variant="h5"
                className={classes.headerTitle}
              >
                Loan Interest & Payment deatils
              </Typography>

              {loanDetails.tags[0].list.map((item, ind) => {
                if (
                  item.descriptor.code !== "INSTALLMENT_AMOUNT" &&
                  item.descriptor.code !== "COOL_OFF_PERIOD"
                ) {
                  return (
                    <Box
                      style={{ display: "inline-block", marginBottom: "20px" }}
                      key={ind}
                    >
                      <Typography
                        variant="body"
                        style={{ display: "block", marginRight: "30px" }}
                        className={classes.descriptionItemList}
                      >
                        {item.descriptor.name}
                      </Typography>
                      <Typography
                        pl={1}
                        variant="body"
                        style={{
                          display: "block",
                          fontWeight: "bold",
                        }}
                        className={classes.descriptionItemList}
                      >
                        {item.descriptor.code === "TNC_LINK" ? (
                          <a href={item.value} rel="noreferrer" target="_blank">
                            Click here
                          </a>
                        ) : (
                          item.value
                        )}
                      </Typography>
                    </Box>
                  );
                }
              })}
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <div className={styles.auth_form}>
          <Typography
            component="div"
            variant="h3"
            my={2}
            align="center"
            color="#196aab"
          >
            Select Loan Amount & Term
          </Typography>

          <form onSubmit={submitLoanDetails}>
            <Grid
              container
              columnSpacing={15}
              mt={8}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={6}>
                <Slider
                  aria-label="Always visible"
                  //valueLabelDisplay="on"
                  valueLabelDisplay="auto"
                  defaultValue={loanMaxValue}
                  value={priceRangeValue}
                  onChange={handlePriceRangeChange}
                  aria-labelledby="input-slider"
                  min={loanMinValue}
                  max={loanMaxValue}
                  step={10000}
                />
                <Input
                  size="small"
                  label_name="Loan Amount"
                  value={selectedAmount}
                />
              </Grid>
              <Grid item xs={6}>
                <Slider
                  aria-label="Always visible"
                  //valueLabelDisplay="on"
                  valueLabelDisplay="auto"
                  defaultValue={loanMaxTermValue}
                  value={termRangeValue}
                  onChange={handleLoanTermRangeChange}
                  aria-labelledby="input-slider-loan-term"
                  min={loanMinTermValue}
                  max={loanMaxTermValue}
                  step={1}
                />
                <Input
                  size="small"
                  label_name="Loan Term in Months"
                  value={selectedLoanTerm}
                />
              </Grid>

              <Grid item xs={12}>
                <div className="py-4 text-center">
                  <Button
                    isloading={isLoading ? 1 : 0}
                    disabled={isLoading}
                    button_type={buttonTypes.primary}
                    button_hover_type={buttonTypes.primary_hover}
                    button_text="Submit"
                    type="submit"
                  />
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </div>
  );
}

export default LoanDetails;
